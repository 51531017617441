export const PRODUCT_CARD_FRAGMENT = `#graphql
  fragment ProductCard on Product {
    id
    title
    publishedAt
    handle
    metafields(identifiers: [{namespace: "descriptors", key: "subtitle"}]) {
      id
      key
      namespace
      value
    }
    media(first: 10) {
      nodes {
        ... on MediaImage {
          id
          image {
            altText
            height
            id
            url(transform:{preferredContentType: WEBP, maxWidth: 800})
            width
          }
          mediaContentType
        }
      }
    }
    options(first: 10) {
      id
      name
      values
    }
    variants(first: 100) {
      nodes {
        id
        availableForSale
        image {
          url(transform:{preferredContentType: WEBP, maxWidth: 500})
          altText
          width
          height
        }
        price {
          amount
          currencyCode
        }
        compareAtPrice {
          amount
          currencyCode
        }
        selectedOptions {
          name
          value
        }
        product {
          handle
          title
        }
        metafields(identifiers: [{namespace: "product", key: "edition_retail_price"}]) {
          id
          key
          namespace
          value
        }
      }
    }
  }
`;
